import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons'
import { Card } from 'react-bootstrap';
import Certificate from "./Certificate";
import data from './certificates.json'




function Education() {
    return (
        <section className="text-center my-3 container" id='Education-section'>
        <div className="row">
            <div className="col-md-6 mx-auto">
            <h1 className="resume-title" id='section-header'>
                    <span className='mx-3'>                    
                    <FontAwesomeIcon icon={faGraduationCap}/>
                    </span>
                <span id='section-title'>Education</span>
            </h1>
            <Card className="bg-dark border-white text-white">
                <Card.Body>
                    <Card.Title>🎓 BAs of Software Engineering</Card.Title>
                    <hr/>
                    <Card.Text>
                        <em>University Of Ottawa</em><br/>
                        <em>ON , Canada</em>
                        <br/>
                        2014 - 2020
                    </Card.Text>
                </Card.Body>

            </Card>
            </div>
        </div>
            <br/>
            <hr/>
            <div className="row">
            <h1 className="resume-title" id='section-header'>
                    <span className='mx-3'>
                    <FontAwesomeIcon icon={faGraduationCap}/>
                    </span>
                <span id='section-title'>Certificates</span>
            </h1>
                            {data.map(function (data) {
            return <Certificate data={data}></Certificate>
                                })}
            </div>
        </section>
    )
}

export default Education
