import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';





/**
 * Import all modules in a directory recursively.
 * @param {Object} r - The require function.
 * @returns {Object} An object containing all modules.
 */
function importAll(r) {
    let articles = [];
    r.keys().map((item, index) => {
        let article = r(item);
        articles[index] = [item.replace('./Article', '').replace('.js', ''), article]
    });
    return articles;
}

// import all Articles
const articles = importAll(require.context('./Article', false, /.js/));

function Articles() {
    return (
        <Container className='content'>
            <ListGroup as="ul">
                {
                    articles.map((element, index) => {
                        return <ListGroup.Item key={index} className="d-flex align-items-start my-2 border" action href={'blog/post/' + element[0]}>
                            <div>
                                <h5>{element[1].title}</h5>
                                <div className='artlist-description'>
                                    {element[1].description}
                                </div>
                                <div>
                                    {element[1].date}
                                </div>
                            </div>
                            <div className="ms-auto">
                                {element[1].tags && element[1].tags.length > 0 &&
                                    element[1].tags.map((index) => {
                                        return <Badge key={index} bg="primary" pill className='artlist-tag'> {index} </Badge>;
                                    })
                                }
                            </div>

                        </ListGroup.Item>
                            ;
                    })
                }
            </ListGroup >

        </Container >
    );
}

export default Articles;