import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/free-solid-svg-icons";


function Navig() {
  return (
    <Navbar collapseOnSelect expand='lg' className="sticky-top p-0" style={{backgroundColor: "rgb(0 0 0 / 85%)"}}>
      <Nav>
        <Navbar.Brand href="/" className='p-0'>
          <img src="/favicon.ico" alt='Home'></img>
        </Navbar.Brand>
      </Nav>

      <Navbar.Toggle />

      <Navbar.Collapse>
        {
        <Nav className='text-center ' variant="tabs">
          <Nav.Link href="#Profile-section" className='p-0'><Link className='text-white fw-bold nav-link' to='/profile'>Profile</Link></Nav.Link>
          <Nav.Link href="#Experience-section" className='p-0'><Link className='text-white fw-bold nav-link' to='/experience'>Experience</Link></Nav.Link>
          <Nav.Link href="#Education-section" className='p-0'><Link className='text-white fw-bold nav-link' to='/education'>Education</Link></Nav.Link>
          <Nav.Link href="#Skills-section" className='p-0'><Link className='text-white fw-bold nav-link' to='/skills'>Skills</Link></Nav.Link>
          <Nav.Link href="#projects-section" className='p-0'><Link className='text-white fw-bold nav-link' to='/projects'>Projects</Link></Nav.Link>


          
        </Nav>
        }

        <Nav className='ms-auto text-center' >
        <Nav.Link className='text-white fw-bold ' target="_blank" href="/MajdiJellaliResume.pdf" active={false}>Resume <FontAwesomeIcon icon={faDownload} /></Nav.Link>
          <Nav.Link className='text-white fw-bold' href="https://buy.stripe.com/cN2aI7fEC6AUaOc6op" target='_blank' active={false}>Buy me Coffee ☕</Nav.Link>
        </Nav>
      </Navbar.Collapse>
      <style>{`
      .nav-link.active > a {
        color: black !important;
      }
      
      `}</style>

    </Navbar>
  )
}

export default Navig