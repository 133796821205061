import Container from 'react-bootstrap/Container';
import { CopyBlock, dracula, irBlack } from 'react-code-blocks';

export const title = "Dynamically Loading React Components in Your Application";
export const description = 'Dynamic component loading in React enables the selective rendering of components based on runtime conditions, enhancing performance and flexibility within web applications.'
export const date = '25/02/2024'
export const tags = ['React', 'Javascript']


const code = [`
project/
|-- src/
|   |-- components/
|   |   |-- Articles.js
|   |   |-- Article/
|   |       |-- Article1.js
|   |       |-- Article2.js
|   |       |-- ...
|   |       |-- ArticleN.js
|-- package.json
|-- webpack.config.js`,
`bash
npm install react-bootstrap`,
`javascript
const path = require('path');

module.exports = {
  // other webpack configurations...

  resolve: {
    alias: {
      'react-dom': '@hot-loader/react-dom',
    },
  },

  module: {
    rules: [
      // other rules...
      {
        test: /\.js$/,
        exclude: /node_modules/,
        use: {
          loader: 'babel-loader',
          options: {
            presets: ['@babel/preset-env', '@babel/preset-react'],
          },
        },
      },
    ],
  },
};`,
`javascript
import React from 'react';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';

// Function to import all modules from a directory
function importAll(r) {
  let articles = [];
  r.keys().map((item, index) => {
    let article = r(item);
    articles[index] = [item.replace('./', ''), article];
  });
  return articles;
}

// Import all articles
const articles = importAll(require.context('./Article', false, /\.js$/));

function Articles() {
  return (
    <Container className="content">
      <ListGroup as="ul">
        {articles.map((element, index) => {
          return (
            <ListGroup.Item key={index} className="d-flex align-items-start my-2 border" action href={'blog/article/\${index}'}>
              <div>
                <h5>{element[1].title}</h5>
                <div className="artlist-description">{element[1].description}</div>
              </div>
              <div className="ms-auto">
                {element[1].tags &&
                  element[1].tags.length > 0 &&
                  element[1].tags.map((tag, index) => {
                    return <Badge key={index} bg="primary" pill className="artlist-tag">{tag}</Badge>;
                  })}
              </div>
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    </Container>
  );
}

export default Articles;
`
];

let rendered = 0;
function renderCode(code) {
    rendered++;
    let language = code[rendered-1].slice(0, code[rendered-1].indexOf("\n"))
    console.log(language)
    code[rendered - 1] = code[rendered - 1].replace(language + "\n", "")
    return <div className="code-block">
        <CopyBlock
            text={code[rendered - 1]}
            language={language}
            showLineNumbers={true}
            wrapLines
            theme={irBlack}
        />
    </div>
}

function Artic() {
    return (
        <Container className='post content'>

            <div>
                {date}
            </div>

            <div>
                <h1>Dynamically Loading Components from a Directory in React</h1>

                <p>In many applications, especially content-heavy ones like blogs, it's common to have a large number of components representing different articles or posts. Manually importing each of these components can become cumbersome and inefficient. To address this issue, we can utilize dynamic component loading to automatically import components from a directory in React. In this guide, we'll explore how to achieve this using React and webpack's require context feature.</p>

                <h2>Understanding Dynamic Component Loading</h2>

                <p>Dynamic component loading involves importing components at runtime based on certain conditions or configurations. This allows us to avoid hardcoding import statements for each component, especially when dealing with a large number of them. In our case, we want to load article components dynamically from a directory.</p>

                <h2>Setting Up the Project</h2>

                <p>Before we dive into dynamic loading, let's set up a basic React project with some sample article components. We'll use webpack's <code>require.context</code> to recursively import all modules within a directory.</p>

                <h3>Project Structure:</h3>

                {renderCode(code)}


                <h3>Installing Dependencies</h3>

                <p>First, ensure you have <code>react-bootstrap</code> installed as a dependency:</p>

                {renderCode(code)}

                <h3>webpack Configuration</h3>

                <p>Ensure your webpack configuration supports dynamic imports using <code>require.context</code>. You can achieve this by adding the following configuration to your <code>webpack.config.js</code>:</p>

                {renderCode(code)}

                <p>This configuration ensures that webpack is set up to handle JSX and ES6 syntax.</p>

                <h2>Implementing Dynamic Component Loading</h2>

                <p>Now, let's implement the dynamic loading logic within our <code>Articles.js</code> component.</p>

                {renderCode(code)}

                <h2>Conclusion</h2>

                <p>Dynamic component loading in React is a powerful technique for managing large sets of components, especially in scenarios like a developer blog where you have numerous articles to display. By utilizing webpack's <code>require.context</code> along with dynamic imports, we can efficiently load components from a directory at runtime, reducing manual effort and improving maintainability. This approach enhances scalability and flexibility, making it easier to manage and extend our React applications.</p>
            </div>
        </Container>
    );
}

export default Artic;