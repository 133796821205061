import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { CopyBlock } from 'react-code-blocks';
import Markdown from 'react-markdown'

const codesource = require('./Article.md')

//console.log(marked.parse(code))

export const title = "Using Markdown Files in React: A Simple Guide";

function Artic() {
    const [markdown, setMarkdown] = useState(null);

    useEffect(() => {
        fetch(codesource)
            .then(res => res.text())
            .then(text => {
                const html = text;
                setMarkdown(html);
                //setMarkdown('```test```');
            })
            .catch(error => {
                console.error('Error fetching article:', error);
            });
    }, []);


    return (
        <Container>
            {markdown && <Markdown>{markdown}</Markdown>}
        </Container>
    );
    
}

export default Artic;