import Row from 'react-bootstrap/Row';
import data from './htb.json'
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { faFlagCheckered } from '@fortawesome/free-solid-svg-icons'
import { faDownload } from '@fortawesome/free-solid-svg-icons'




function Item(props) {
    return (
        <Card style={{ width: '18rem' }} className="mx-auto mb-3 bg-dark text-white border-white" id='Services'>
            <Card.Body>
                {props.data.image !== '' &&
                    <Card.Img variant="top" src={props.data.image} />}
                <Card.Title className='my-2 fs-3' id='service-title'>{props.data.name}</Card.Title>
                <Card.Subtitle className="mb-2 border-white">{props.data.type}</Card.Subtitle>
                <hr />
                {props.data.task !== "" &&
                    <Card.Text className='text-white text-start'>
                        {props.data.task}
                    </Card.Text>
                }
            </Card.Body>
            <Card.Footer>
                {props.data.link !== "" &&
                    <Card.Link href={props.data.link} target='_blank'>
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </Card.Link>
                }
                {props.data.buy !== "" &&
                    <Card.Link href={props.data.buy} target='_blank'>
                        <FontAwesomeIcon icon={faDownload} />
                    </Card.Link>
                }
            </Card.Footer>
        </Card>
    )
}

function Htb() {
    return (
        <section className="text-center my-3 container" id='Projects-section'>
            <h1 className='' id='section-header'>
                <span className='mx-3'>
                    <FontAwesomeIcon icon={faFlagCheckered} />
                </span>
                <span id='section-title'>
                    HachTheBox writeups
                </span>
            </h1>
            <Row>
                {data.map(function (data) {
                    return <Item data={data} />
                })}
            </Row>
        </section>
    );
}

export default Htb;
