import Container from 'react-bootstrap/Container';
import data from './TechSpinner.json'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBriefcase } from '@fortawesome/free-solid-svg-icons'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

function TechSpinner() {
    return (
        <Carousel additionalTransfrom={0}
        arrows={false}
        autoPlay
        autoPlaySpeed={3000}
        centerMode={true}
        customTransition="transform 3s linear"
        //transitionDuration
        className="slider-container my-3"
        containerClass="container-with-dots"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite
        itemClass="slider-item"
        keyBoardControl
        minimumTouchDrag={80}
        pauseOnHover
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
            desktop: {
                breakpoint: {
                    max: 3000,
                    min: 1024
                },
                items: 5,
                partialVisibilityGutter: 40
            },
            mobile: {
                breakpoint: {
                    max: 464,
                    min: 0
                },
                items: 1,
                partialVisibilityGutter: 30
            },
            tablet: {
                breakpoint: {
                    max: 1024,
                    min: 464
                },
                items: 1,
                partialVisibilityGutter: 30
            }
        }}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        showDots={false}
        sliderClass="slider"
        slidesToSlide={1}
        swipeable
        >
        {data.map(function (tech, index) {
            return <div className='h-100' key={index.toString()}>
            <img className='rounded mx-auto '
                style={{ backgroundColor: 'transparent', maxWidth:'100px', maxHeight:'100px'}}
                id={tech.title}
                title={tech.title}
                alt={tech.title}
                src={tech.logo}
            />
            </div>
        })}

    </Carousel>
    )
}

export default TechSpinner