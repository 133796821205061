import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Projects from './Components/Projects'
import Layout from './Components/Layout';
import Home from './Components/Home';
import Blog from './Components/Blog';
import Articles from './Components/Blog/Articles';
import Article from './Components/Blog/Article';
import My404Component from './Components/My404Component';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'
import Experience from './Components/Experience/Experience';
import About from './Components/About/About';
import Skills from './Components/Skills/Skills';
import Education from './Components/Education/Education';
import Profile from './Components/Profile/Profile';
import Htb from './Components/Htb/Htb';
import Services from './Components/Services/Services';

const analytics = Analytics({
  app: 'Profile',
  plugins: [
    googleAnalytics({
      measurementIds: ['G-WPBMBTJ5QR']
    })
  ]
})

const root = ReactDOM.createRoot(document.getElementById('root'));

analytics.page()

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/blog" element={<Blog />}>
        <Route index element={<Articles />} />
        <Route path="post/:id" element={<Article />} />
      </Route>
      <Route path="/" element={<Layout />}>
          <Route index element={<Profile />} />
          <Route path="about" element={<About />} />
          <Route path="experience" element={<Experience />} />
          <Route path="skills" element={<Skills />} />
          <Route path="education" element={<Education />} />
          <Route path="profile" element={<Profile />} />
          <Route path="projects" element={<Projects />} />
          <Route path="htb" element={<Htb />} />
          {//<Route path="services" element={<Services />} />\
          }
      </Route>
      <Route path='*' element={<My404Component />} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
