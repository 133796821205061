import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEarthAmericas } from '@fortawesome/free-solid-svg-icons'
import { faUser, faEnvelope } from '@fortawesome/free-regular-svg-icons'
import Container from 'react-bootstrap/Container';
import { SocialIcon, register } from 'react-social-icons';
import { Link } from 'react-router-dom'

register('hackthebox', {
  color: '#9FEF00',
  path: 'M31.7,17c0.2,0,0.3,0,0.5,0c0.2,0,0.4,0.2,0.6,0.3c3.9,2.3,7.8,4.5,11.7,6.8c0.4,0.2,0.7,0.6,0.6,1V39 	c0,0.4-0.2,0.8-0.6,0.9c-4,2.3-8,4.6-12,6.9c-0.4,0.2-0.8,0.2-1.2,0c-3.9-2.3-7.8-4.5-11.7-6.8c-0.2-0.1-0.4-0.2-0.6-0.4 	c-0.1-0.1-0.2-0.4-0.2-0.7V25c0-0.4,0.2-0.8,0.6-0.9c3.9-2.3,7.9-4.5,11.8-6.8C31.4,17.2,31.5,17.1,31.7,17L31.7,17z M31.9,19.8 	c-0.1,0-0.2,0-0.2,0.1c-2.7,1.5-5.4,3.1-8.1,4.7c-0.3,0.2-0.3,0.7,0,0.9c2.7,1.6,5.4,3.1,8.2,4.7c0.2,0.1,0.4,0.1,0.6,0 	c2.7-1.6,5.4-3.1,8.2-4.7c0.3-0.2,0.3-0.7,0-0.9c-2.7-1.6-5.4-3.1-8.2-4.7C32.2,19.8,32,19.8,31.9,19.8L31.9,19.8z M21.7,27.9 	c-0.2,0.1-0.3,0.3-0.3,0.5v9.4c0,0.2,0.1,0.4,0.3,0.5c2.7,1.5,5.4,3.1,8.1,4.7c0.3,0.2,0.8-0.1,0.8-0.5v-9.4c0-0.2-0.1-0.4-0.3-0.5 	L22.2,28C22.1,27.9,21.9,27.8,21.7,27.9L21.7,27.9z M41.9,27.9c-0.2,0.1-0.5,0.3-0.7,0.4c-2.5,1.5-5,2.9-7.5,4.4 	c-0.2,0.1-0.3,0.3-0.3,0.5v9.4c0,0.4,0.4,0.7,0.8,0.5c2.7-1.5,5.4-3.1,8.1-4.7c0.2-0.1,0.3-0.3,0.3-0.5v-9.4 	C42.6,28.1,42.2,27.8,41.9,27.9z' // see https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/d#path_commands
})

register('devto', {
    color: 'black',
    path: 'm17.25,25.31c-0.55,-0.41 -1.11,-0.62 -1.66,-0.62l-2.49,0l0,14.92l2.49,0c0.55,0 1.11,-0.21 1.66,-0.62s0.83,-1.04 0.83,-1.87l0,-9.95c0,-0.83 -0.28,-1.45 -0.83,-1.87zm40.49,-25.18l-51.36,0c-3.45,0 -6.25,2.8 -6.26,6.26l0,51.49c0.01,3.46 2.81,6.26 6.26,6.26l51.36,0c3.45,0 6.25,-2.8 6.26,-6.26l0,-51.49c-0.01,-3.46 -2.81,-6.26 -6.26,-6.26zm-35.63,37.03c0,2.69 -1.66,6.76 -6.9,6.75l-6.62,0l0,-23.64l6.76,0c5.05,0 6.75,4.07 6.75,6.75l0,10.13zm14.35,-12.67l-7.6,0l0,5.49l4.64,0l0,4.22l-4.64,0l0,5.49l7.6,0l0,4.22l-8.87,0c-1.59,0.04 -2.91,-1.22 -2.95,-2.81l0,-17.87c-0.04,-1.59 1.22,-2.92 2.81,-2.96l9.01,0l0,4.22zm14.78,16.47c-1.88,4.39 -5.25,3.52 -6.76,0l-5.49,-20.69l4.64,0l4.24,16.25l4.22,-16.25l4.65,0l-5.48,20.69z'
  })

function Profile() {
    return (
        <section className="my-3" id='Profile-section'>

        <Card className="bg-dark text-white border" id='profile'
            style={{ marginTop:'150px'}}
        >
        <div className='' 
                style={{ position:'', height:'100px'}}
            >
            <Card.Img className="rounded-circle img-thumbnail" src="Photo_Pro_Majdi_Side_square.jpg"
                style={{ width: '200px', height: '200px', top:'-100px', left:'50%', position:'absolute', transform: 'translate(-50%, 0)'}}
            />
                </div>
            <Card.Body>
                
                <Card.Title id=''>
                    <h3 className='d-inline'>
                        <FontAwesomeIcon icon={faUser} className='me-3' />
                        <span class="align-middle">Majdi Jellali</span>
                    </h3>
                </Card.Title>
                <hr />
                <Container>
                    <div className='profile-title'>
                        <h5>Software Engineer | Developer | Pentester</h5>
                    </div>
                    <div>
                        <div className='my-1'>
                            <span class="align-middle"><FontAwesomeIcon className='me-2' icon={faEnvelope} />
                        <Link className="text-decoration-none" href="mailto:maj.jellali@gmail.com">maj.jellali@gmail.com</Link>
                        </span>
                        </div>
                        <div className=''>
                            <span class="align-middle"><FontAwesomeIcon className='me-2' icon={faPhone} />+1 819 930 4955</span></div>
                        <div><span class="align-middle"><FontAwesomeIcon className='me-2' icon={faEarthAmericas} /> <Link className="text-decoration-none" href='http://majjel.com'>www.majjel.com</Link> </span></div>
                    </div>
                    <Container className='text-center d-inline-block mt-3'>
                        <SocialIcon url="https://facebook.com/majdi.jellali" target="_blank" />
                        <SocialIcon url="https://linkedin.com/in/majdi-jellali-98b1aaa8" target="_blank" />
                        <SocialIcon url="https://github.com/mjdjll" target="_blank" id="github-icon" fgColor='black' bgColor='white'/> 
                        <SocialIcon url="https://gitlab.com/mjd.jellali" target="_blank" />
                        <SocialIcon url="https://medium.com/@mjd.jellali" target="_blank"/>
                        <SocialIcon network='devto' url="https://dev.to/mjdjll" target="_blank" fgColor='white' />
                        <SocialIcon network='hackthebox' url="https://app.hackthebox.com/profile/1751743" target="_blank" fgColor='black' />
                        
                    </Container>
                </Container>
            </Card.Body>
            <style>{`
                #github-icon > div >svg > g.social-svg-mask {
                    fill: white !important;
                }
            `}</style>
        </Card>
        </section>
    )
}

export default Profile;