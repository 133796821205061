import Container from 'react-bootstrap/Container';
import { CopyBlock, dracula } from 'react-code-blocks';

export const title = "Title";
export const description = 'Description'
export const date = '25/02/2024'
export const tags = ['tag0', 'tag1']


const code = [`project`];

let rendered = 0;
function renderCode(code) {
    rendered++;
    return <div className="code-block">
        <CopyBlock
            text={code[rendered - 1]}
            showLineNumbers={true}
            wrapLines
            theme={dracula}
        />
    </div>
}

function Artic() {
    return (
        <Container className='post content'>

            <div>
                {date}
            </div>

            <div>
                <h1>{title}</h1>

                </div>
        </Container>
    );
}

export default Artic;