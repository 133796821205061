import Container from 'react-bootstrap/Container';
import Skill from './Skill'
import Row from 'react-bootstrap/Row';
import data from './skills.json'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLaptopCode } from '@fortawesome/free-solid-svg-icons'




function Skills() {

    return (
        <section className="text-center my-3 container" id='Skills-section'>
                <h1 className='' id='section-header'>
                    <span className='mx-3'>
                        <FontAwesomeIcon icon={faLaptopCode} />
                    </span>
                    <span id='section-title'>
                        Skills
                    </span>
                </h1>
                <Row>
                    {data.map(function (data, index) {
                        return <Skill key={index.toString()} data={data} />
                    })}
                </Row>
        </section>
    )
}

export default Skills