import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';


function Skill(props) {
    return (
        <Card style={{ width: '18rem' }} className="mx-auto mb-3 bg-dark border-white text-white" id='skill'>
            <Card.Body>
                <Card.Title className='' id='skill-title'>{props.data.category}</Card.Title>
                <hr />
                <Container style={{'textAlign': 'left'}}>
                    <ul>
                        {props.data.list.map(function (data) {
                            return <li key={data.toString()}>{data}</li>
                        })}
                    </ul>
                </Container>
            </Card.Body>
        </Card>
    )
}

export default Skill