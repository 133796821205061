import Container from 'react-bootstrap/Container';



function About() {
    return (
        <section className="text-center my-3" id='About-section'>
            <Container className=''>
                <h1 className='' id='section-header'>
                    <span id='section-title'>About me</span></h1>
                    <div className="card">

                <p className="lead bg-dark text-white m-0">
                    👋 Hey there! I'm Majdi a passionate software developer on a mission to craft innovative solutions and build seamless digital experiences.
                    <br />

                    💻 With 5 years of experience in the industry, I've honed my skills in programming languages/technologies/frameworks, mastering the art of turning complex problems into elegant, efficient code.
                    <br />
                    <br />

                    🚀 My journey in software development has taken me through a diverse range of projects, from creating scalable web applications to developing robust business process workflows. I thrive in dynamic environments where I can collaborate with talented teams to push the boundaries of what's possible.
                    <br />
                    <br />

                    🔍 I have a keen eye for detail and a relentless pursuit of excellence, always striving to deliver products that not only meet but exceed expectations. Whether it's optimizing performance, enhancing user experience, or solving intricate technical challenges, I'm up for the task.
                    <br />
                    <br />

                    🌟 When I'm not immersed in lines of code, you can often find me delving into the world of Capture The Flag (CTF) competitions. I love the thrill of solving complex cybersecurity challenges and honing my skills in a fast-paced, competitive environment.
                    <br />
                    <br />

                    📚 Beyond coding and CTF, I'm deeply committed to staying at the forefront of technology trends and continuously learning and growing as a developer. I'm enthusiastic about sharing my knowledge and expertise with others through mentoring, writing, and contributing to the developer community.
                    <br />
                    <br />

                    📫 Let's connect and embark on exciting projects together! Whether you're looking to build something groundbreaking, wanting to discuss the latest tech trends, or even share tips for mastering CTF competitions, I'm all ears.
                </p>
                </div>
            </Container >
        </section >
    )
}

export default About
