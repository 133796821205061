import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NavBar from './Blog/NavBar'
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Outlet} from "react-router-dom";

const Blog = () => {
  return (
    <div className="App">
      
      <Container className=''>
        <Row>
          <Col className="navbar-wrapper"><NavBar /></Col>
        </Row>
        <Row>
          <Col>
            <Outlet></Outlet>
          </Col>
        </Row>
      </Container>

    </div>
  );
};

export default Blog;