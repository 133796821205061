import Container from 'react-bootstrap/Container';
import data from './experience.json'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBriefcase } from '@fortawesome/free-solid-svg-icons'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

function Exp(props) {
    return (
        <Container className='text-start pb-2 px-0'>
            <div className="card p-2 bg-dark border-white text-white">
                <h2 className='' id='job-title'>
                    {props.data.role}
                </h2>
                <h3 className='text-secondary'>
                    {props.data.company}
                </h3>
                <h4 className='text-secondary'>
                    {props.data.date}
                </h4>
                <ul className=''>
                    {props.data.tasks.map(function (task, index) {
                        return <li key={index.toString()}>{task}</li>
                    })}
                </ul>
                <div>
                    {props.data.techStack.map(function (tech, index) {
                        return <img className='tech-logo ' height='50px'
                            style={{ display: 'inline-flex', margin: '0.25em', backgroundColor: '#e5e5e5' }}
                            key={index.toString()}
                            id={tech.title}
                            title={tech.title}
                            alt={tech.title}
                            src={tech.logo}
                        />
                    })}
                </div>
                </div>

        </Container>
    )
}

function Experience() {
    return (
        <section className="text-center container my-3" id='Experience-section'>
                <h1 className='' id='section-header'>
                    <span className='mx-3'>
                        <FontAwesomeIcon icon={faBriefcase} />
                    </span>
                    <span id='section-title'>
                        Experience
                    </span>
                </h1>
                {data.map(function (data, index) {
                    return <Exp key={index.toString()} data={data} />
                })}
            <style jsx="true">{`
                `}</style>
            <script>
                {``}
            </script>

        </section>
    )
}

export default Experience