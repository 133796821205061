import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import About from './About/About'
import Education from './Education/Education'
import Experience from './Experience/Experience'
import Skills from './Skills/Skills'
import Services from './Services/Services'
import { useState } from 'react';
import { Outlet } from 'react-router-dom';



function Home() {

  return (
    <Container>
      <Outlet />
    </Container>
  );
}

export default Home;
