import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";


function Certificate(props) {
    console.log(props);
    return (
        <Card style={{ width: '18rem' }} className="mx-auto mb-3 bg-dark text-white border-white" id='Services'>
            <Card.Body>
                {props.data.image !== '' &&
                    <Card.Img variant="top" src={props.data.image} />}
                <Card.Title className='my-2 fs-3' id='service-title'>{props.data.name}</Card.Title>
                <Card.Subtitle className="mb-2 border-white">{props.data.fullName}</Card.Subtitle>
                <hr />
                {props.data.task !== "" &&
                    <Card.Text className='text-white text-start'>
                        {props.data.date}
                    </Card.Text>
                }
            </Card.Body>
            <Card.Footer>
                {props.data.link !== "" &&
                    <Card.Link href={props.data.link} target='_blank'>
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </Card.Link>
                }
            </Card.Footer>
        </Card>
    )
}

export default Certificate