import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import data from './projects.json'
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { faProjectDiagram } from '@fortawesome/free-solid-svg-icons'
import { faCode } from '@fortawesome/free-solid-svg-icons'




function Project(props) {
    return (
        <Card style={{ width: '18rem' }} className="mx-auto mb-3 bg-dark text-white border-white" id='Services'>
            <Card.Body>
                {props.data.image != '' &&
                    <Card.Img variant="top" src={props.data.image} />}
                <Card.Title className='my-2 fs-3' id='service-title'>{props.data.service}</Card.Title>
                <Card.Subtitle className="mb-2 border-white">{props.data.description}</Card.Subtitle>
                <hr />
                {props.data.task !== "" &&
                    <Card.Text className='text-white text-start'>
                        {props.data.task}
                    </Card.Text>
                }
            </Card.Body>
            <Card.Footer>
                {props.data.repo !== "" &&
                    <Card.Link href={props.data.repo} target='_blank'>
                        <FontAwesomeIcon icon={faCode} />
                    </Card.Link>
                }
                {props.data.link !== "" &&
                    <Card.Link href={props.data.link} target='_blank'>
                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                    </Card.Link>
                }</Card.Footer>
        </Card>
    )
}

function Projects() {
    return (
        <section className="text-center my-3 container" id='Projects-section'>
            <h1 className='' id='section-header'>
                <span className='mx-3'>
                    <FontAwesomeIcon icon={faProjectDiagram} />
                </span>
                <span id='section-title'>
                    Projects
                </span>
            </h1>
            <Row>
                {data.map(function (data) {
                    return <Project data={data} />
                })}
            </Row>
        </section>
    );
}

export default Projects;
