import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Profile from './Profile/Profile'
import { Outlet } from "react-router-dom";
import TechSpinner from "./TechSpinner/TechSpinner";
import Navig from './Navig/Navig';


const Layout = () => {
  return (
    <div className="App bg-dark text-white min-vh-100">
      <Container className='min-vh-100 position-relative'>
        <Row>
          {/*<Col lg='3' className="p-0">
            <Profile />
          </Col>
  */}
          <Col >
            <Container className='' >
              <Navig />

              <Row>
                <Outlet />
              </Row>
            </Container>
          </Col>

        </Row>
        <Row>
            <TechSpinner />
            </Row>
        <div className="position-absolute" style={{ bottom: '0' }}>
          <Container>
            
          </Container>
          <footer>&copy; Copyright 2024 majjel.com</footer>
        </div>
      </Container>

      <style>{`
        
      `}</style>

    </div>
  );
};

export default Layout;