import Container from 'react-bootstrap/Container';
import { useParams } from "react-router-dom";
import { Navigate } from "react-router-dom";



function Article() {
    // Get Article name
    let { id } = useParams();

    if (isNaN(+id)){
        return window.location.replace('https://sinclert.github.io/assets/images-posts/react-env/one-does-not-simply-meme.jpg');
    }
    let articleName = 'Article'+id+'.js';
    // Get Article as react compoenent
    try {
        let MyArticle = require('./Article/'+articleName).default;
        console.log(MyArticle);
        return (
                <Container>
                    <MyArticle></MyArticle>
                </Container>
        );
    }
     catch (e) {
        return <h1>Post not found! we will get there soon!</h1>
    }
}

export default Article;